<template>
  <div class="main-view">
    <loader v-if="isLoading"></loader>
    <krn-viewer v-if="model.krn" :krn="model.krn"></krn-viewer>
    <form>
      <formly-form
        :form="form"
        :model="model"
        :fields="fields1">
      </formly-form>
      <br>
      <button
        :aria-label="submitText"
        type="button"
        class="button is-primary"
        :disabled="checkFormErrors"
        @click="submit"><i class="mdi mdi-check"/>&nbsp;{{submitText}}
      </button>&nbsp;&nbsp;
      <button
        :aria-label="cancelText"
        type="button"
        class="button is-light"
        @click="cancel">
        <i class="mdi mdi-cancel"/>&nbsp;{{cancelText}}</button>
    </form>
  </div>
</template>

<script>
import vueFormly from 'vue-formly';
import EventBus from '../eventbus';
import KrnViewer from './krn/krnViewer.vue';

const Roles = () => import(/* webpackChunkName: "PaymentProviders" */ '@/formModels/users/Roles.vue');
const Blocked = () => import(/* webpackChunkName: "HiddenField" */ '@/formModels/users/Blocked.vue');
const MerchantTypes = () => import(/* webpackChunkName: "PaymentProviders" */ '@/formModels/merchants/PaymentProviders.vue');
const CurrencyCodes = () => import(/* webpackChunkName: "CurrencyCodes" */ '@/formModels/generic/CurrencyCodes.vue');
const CountryCode = () => import(/* webpackChunkName: "CountryCode" */ '@/formModels/generic/CountryCodes.vue');
const HiddenField = () => import(/* webpackChunkName: "HiddenField" */ '@/formModels/generic/HiddenField.vue');


vueFormly.addType('currency-codes', CurrencyCodes);
vueFormly.addType('country-code', CountryCode);
vueFormly.addType('merchant-types', MerchantTypes);
vueFormly.addType('hidden-field', HiddenField);
vueFormly.addType('roles', Roles);
vueFormly.addType('blocked', Blocked);

const flatten = (object, prefix = '') => {//eslint-disable-line
  return Object.keys(object).reduce((prev, element) => { //eslint-disable-line
    return object[element] && typeof object[element] === 'object' && !Array.isArray(element)
      ? { ...prev, ...flatten(object[element], `${prefix}${element}.`) }
      : { ...prev, ...{ [`${prefix}${element}`]: object[element] } };
  }, {});
};

export default {
  name: 'GenericForm',
  components: { KrnViewer },
  props: {
    dynamicFields: Object,
    afterCreate: String, // Component to go to after create
    baseModel: Object,
    baseFields: Array,
    cancelRoute: String, // Component to go to on cancel
    cancelText: {
      type: String,
      default: 'Cancel',
    },
    createOrUpdate: String,
    entityName: String,
    getDataMethod: String,
    getDataParams: Array,
    method: String,
    postDataMethod: String,
    postDataParams: Array,
    putDataMethod: String,
    putDataParams: Array,
    putExcludedFields: {
      type: Array,
      default: () => [],
    },
    submitText: {
      type: String,
      default: 'Add',
    },
  },
  mounted() {
    EventBus.$on('fields', (fields, model) => {
      this.changeFields(fields, model);
    });
    this.dataService = this.$dataService(this.$route.params.tenant);
    if (this.getDataMethod) {
      this.applyData();
    }
  },
  computed: {
    checkFormErrors() {
      const obj = this.form.$errors;

      if (obj) {
        const flatObj = flatten(obj);
        const errorValues = Object.values(flatObj);
        if (errorValues.indexOf(true) > -1) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    cancel() {
      this.$router.push({ name: this.cancelRoute });
    },
    refreshData() {
      this.$router.push({ name: this.afterCreate });
    },
    getRouteParamValues() {
      return this.getDataParams.map(param => this.$route.params[param]);
    },
    putRouteParamValues() {
      return this.putDataParams.map((param) => {
        let paramValue;
        switch (param) {
          case 'data':
            paramValue = this.model;
            break;
          case 'adapter': {
            paramValue = this.$route.params.adapter;
            break;
          }
          case 'tenant':
            paramValue = this.$route.params.tenant;
            break;
          default:
            paramValue = this.model[param];
        }
        return paramValue;
      });
    },
    postRouteParamValues() {
      // if not in data, look in route params
      return this.postDataParams.map(
        param => this.$data[param]
              || this.$route.params[param]
              || this.$data.model[param],
      );
    },
    excludeInvalidPutFields() {
      const excludedFields = this.$props.putExcludedFields;
      const data = { ...this.model };

      Object.keys(data).forEach((key) => {
        if (excludedFields.indexOf(key) > -1) {
          delete data[key];
        }
      });

      return data;
    },
    applyData() {
      this.isLoading = true;
      this.dataService[this.getDataMethod](...this.getRouteParamValues())
        .then(res => res.data)
        .then((data) => {
          this.model = data;

          Object.keys(data).forEach((key) => {
            // key: the name of the object key
            // index: the ordinal position of the key within the object
            if (Object.prototype.hasOwnProperty.call(this.model, key)) {
              if (data[key]) {
                this.model[key] = data[key];
              }
            }
          });

          if (this.$props.dynamicFields) {
            const { dynamicFields } = this.$props;
            const { dynamicFieldName } = this.$props.dynamicFields;
            const dynamicFieldNameValue = this.model[dynamicFieldName];
            this.fields1 = dynamicFields.fieldValues[dynamicFieldNameValue];
          }

          for (const key in data) { //eslint-disable-line
            if (Object.prototype.hasOwnProperty.call(this.model, key)) {
              this.model[key] = data[key];
            }
          }
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    changeFields(fields) { // model arg removed from here <--
      this.fields1 = fields;
    },
    submit() {
      this.isLoading = true;
      if (this.$props.method === 'POST') {
        this.dataService[this.createOrUpdate](...this.postRouteParamValues())
          .then((res) => {
            this.isLoading = false;
            if (res.status === 200 || res.status === 204) {
              this.$buefy.toast.open(`${this.$props.entityName} record created`);
              this.refreshData();
            } else {
              this.$buefy.toast.open(`Unable to create ${this.$props.entityName} record`);
            }
          })
          .catch((e) => {
            this.isLoading = false;
            this.$buefy.dialog.alert({
              title: 'Error',
              message: e.response.data.message,
              type: 'is-danger',
              hasIcon: true,
              icon: 'alert-circle-outline',
              iconPack: 'mdi',
            });
          });
      }
      if (this.$props.method === 'PUT') {
        this.model = this.excludeInvalidPutFields();
        this.dataService[this.createOrUpdate](...this.putRouteParamValues())
          .then((res) => {
            this.isLoading = false;
            if (res.status === 200 || res.status === 204) {
              this.$buefy.toast.open(`${this.$props.entityName} record updated`);
              this.refreshData();
            } else {
              this.$buefy.toast.open(`Unable to update ${this.$props.entityName} record`);
            }
          })
          .catch((e) => {
            this.isLoading = false;
            this.$buefy.dialog.alert({
              title: 'Error',
              message: e.response.data.message,
              type: 'is-danger',
              hasIcon: true,
              icon: 'alert-circle-outline',
              iconPack: 'mdi',
            });
          });
      }
    },
  },
  data() {
    return {
      isLoading: false,
      form: {},
      model: {},
      fields1: this.$props.baseFields,
    };
  },
};
</script>
