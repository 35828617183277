<template>
  <div>
    <p class="krn-heading">KRN</p>
    <p class="krn">{{krn}}</p>
  </div>
</template>

<script>
export default {
  props: ['krn'],
};
</script>

<style lang="scss" scoped>
  .krn-heading {
    font-weight: bold;
  }

  .krn {
    margin-bottom: 1em;
  }
</style>
